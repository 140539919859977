import React, { useContext } from "react";
import { Carousel, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MainContent } from "../../App";
import muslimf from "../../images/avatar_female.jpg";
import noimage from "../../images/avatar_male.jpg";

function PeoplePhoto(props) {
  const { t } = useTranslation();
  const { language } = useContext(MainContent);
  const { viewpic } = props;

  const imageOnError = (event) => {
    event.currentTarget.src = props.gender ? noimage : muslimf;
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {" "}
        <span
          className={
            language === "ar" ? "py-2 text-right w-full font-bold" : "font-bold"
          }
        >
          {t("Profile_Pictures")}
        </span>{" "}
      </Modal.Header>
      <Modal.Body>
        <Carousel>
          {viewpic.map((item, ind) => (
            <Carousel.Item key={ind}>
              <img
                style={{ width: "100%", height: "450px" }}
                src={`${process.env.REACT_APP_IMAGE}${item?.path}`}
                alt=""
                onError={imageOnError}
              />
            </Carousel.Item>
          ))}
        </Carousel>
      </Modal.Body>
    </Modal>
  );
}

export default PeoplePhoto;
