import React, { useContext, useEffect, useState } from "react";
import { Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  AiFillCamera,
  AiFillCloseCircle,
  AiOutlineWarning,
} from "react-icons/ai";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { RiMessage2Fill } from "react-icons/ri";
import { MainContent } from "../../../App";
import muslimf from "../../../images/avatar_female.jpg";
import noimage from "../../../images/avatar_male.jpg";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { errorHandler, url } from "../../../api/Api";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import debounce from "../../../utils/debounce";
import { customToasterStyle } from "../../../utils/constants";
import Flag from "react-world-flags";

const Cart = ({ data }) => {
  const history = useNavigate();
  const { t } = useTranslation();
  const {
    grid,
    changnow,
    myown_data,
    language,
    isCurrentProfileApproved,
    isCurrentProfileAvailable,
    isMarkedForDeletion,
  } = useContext(MainContent);
  const [myid, setMyid] = useState("");
  const [sendmessage, setSendmessage] = useState(false);
  const [like, setLike] = useState(false);
  const [body, setBody] = useState("");
  const [ispaid, setIspaid] = useState(false);
  const [succeed, setSucceed] = useState();

  const imageOnError = (event) => {
    event.currentTarget.src = data.gender ? noimage : muslimf;
  };

  const imageSrc = data.gender ? noimage : muslimf;

  const photoRequest = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      profileId: data.profileid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${url}image/requestaccess`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setSucceed(true);
          toast.success(t("Successfully_Requesting_For_Profile_Photos"), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        } else {
          toast.error(
            result.errors === null ? result.message : result.errors[0],
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              style: customToasterStyle(language),
            }
          );
        }
      });
  };

  const sendPhotoRequest = debounce(photoRequest, 500); // Debounce delay in milliseconds

  const handleButtonClick = () => {
    sendPhotoRequest(); // Call the debounced function
  };

  const renderOnlineTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("IsOnlineNow")}
    </Tooltip>
  );

  const renderOfflineTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {t("IsOffline")}
    </Tooltip>
  );

  useEffect(() => {
    setLike(data.isliked);
    setSucceed(data.hasaccessalreadybeenrequested);
    setMyid(myown_data.data.profileid);
    setIspaid(myown_data.data.profileattributes.isapaidmember);
  }, [changnow]);

  const likeProfile = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      profileId: myid,
      likeprofileid: data.profileid,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}profile/like/add`, requestOptions)
      .then((response) => {
        errorHandler(response);
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          setLike(true);
          if (result.success) {
            Swal.fire({
              title: result.message,
              //text: result.message,
              confirmButtonText: t("Ok"),
              confirmButtonColor: "#032E42",
              icon: "success",
              timer: 4000,
            });
          }
        }
      });
  };

  const sendMessage = (e) => {
    e.preventDefault();
    setSendmessage(false);
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${Cookies.get("accessToken")}`);
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept-Language", language || "en");

    let raw = JSON.stringify({
      body: body,
      fromProfileId: myid,
      toProfileId: data.profileid,
      messagetypeid: 1,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${process.env.REACT_APP_URI}inbox/newmessage`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        if (result.success) {
          toast.success(result.message, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        } else {
          toast.error(result.message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: customToasterStyle(language),
          });
        }
      });
  };

  const profileNotActiveOrNotApprovedHandler = () => {
    if (!isCurrentProfileApproved) {
      showSwal(
        t("AwaitingApproval_Header"),
        t("AwaitingApproval_Message"),
        "info"
      );
      return;
    }

    if (!isCurrentProfileAvailable) {
      showSwal(
        t("ProfileNotActive_Header"),
        t("ProfileNotActive_Message"),
        "info"
      );
      return;
    }
    if (isMarkedForDeletion) {
      showSwal(
        t("ProfileMarkedForDeletion_Header"),
        t("ProfileMarkedForDeletion_Message"),
        "info"
      );
    }
  };

  const showSwal = (title, message, type) => {
    Swal.fire({
      title: title,
      text: message,
      icon: type,
      confirmButtonText: t("Ok"),
      confirmButtonColor: "#032E42",
    });
  };

  const confirmShowInterestHandler = () => {
    Swal.fire({
      title: t("Confirmation"),
      html: t("Express_Interest_Confirm_Message"),
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#032E42",
      cancelButtonColor: "#808080",
      confirmButtonText: t("Express_Interest_Button"),
      cancelButtonText: t("Cancel"),
    }).then((result) => {
      if (result.isConfirmed) {
        likeProfile();
      }
    });
  };

  return (
    <>
      {sendmessage &&
        (data.inboxid === null ||
        data.inboxid === undefined ||
        data.isinboxempty ? (
          <div
            className="fixed top-0 left-0 "
            style={{
              height: "100%",
              width: "100%",
              background: "rgba(0,0,0,0.3)",
              zIndex: "9999999",
            }}
          >
            <div
              style={{ height: "500px" }}
              // className="sm:w-80 md:w-96 lg:w-96 xl:w-96 xxl:w-96 fixed bottom-2 px-2 border-2 border-sr overflow-hidden bg-white shadow-md rounded-xl right-10"
              // className="w-96 fixed bottom-2 px-2 border-2 border-sr overflow-hidden bg-white shadow-md rounded-xl right-10"
              className="w-full sm:w-96 md:w-96 lg:w-96 fixed lg:right-10 md:right-5 right-1 bottom-2 px-2 border-2 border-pr overflow-hidden bg-white  shadow-md rounded-xl "
              // className="w-full sm:max-w-xs md:max-w-sm lg:max-w-lg fixed lg:right-10 md:right-5 right-1 bottom-2 px-2 border-2 border-pr overflow-hidden bg-white shadow-md rounded-xl "
            >
              <div className="flex items-center justify-end pt-2 mb-2">
                <AiFillCloseCircle
                  onClick={() => setSendmessage(false)}
                  className="text-sr w-6 h-6 cursor-pointer"
                />
              </div>
              <div className="flex items-center justify-center flex-col mb-3">
                <Link to={"/profile/" + data.profileid}>
                  <div className="h-16 w-16 rounded-full  border-gray-300 border-2 overflow-hidden mb-2 ">
                    {data.url !== "" ? (
                      <img
                        className="h-full w-full object-cover "
                        src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                        alt=""
                        onContextMenu={(e) => e.preventDefault()}
                        onError={imageOnError}
                      />
                    ) : (
                      <img
                        className="h-full w-full object-cover "
                        src={imageSrc}
                        alt=""
                        onError={imageOnError}
                        onContextMenu={(e) => e.preventDefault()}
                      />
                    )}
                  </div>
                  <h6
                    style={{ marginLeft: "-10px", direction: "ltr" }}
                    className="font-medium text-gray-700"
                  >
                    {data.displayusername}
                  </h6>
                </Link>
              </div>
              <form
                className="relative h-full"
                onSubmit={(e) => sendMessage(e)}
              >
                {ispaid &&
                isCurrentProfileApproved &&
                isCurrentProfileAvailable &&
                !isMarkedForDeletion ? (
                  <div>
                    <textarea
                      onChange={(e) => setBody(e.target.value)}
                      required
                      maxLength="4000"
                      autoFocus
                      cols="25"
                      rows="10"
                      placeholder={t("Write_Your_Message_Here")}
                      className="outline-none border rounded-md p-3 w-full h-72 "
                    ></textarea>
                  </div>
                ) : !ispaid &&
                  isCurrentProfileApproved &&
                  isCurrentProfileAvailable &&
                  !isMarkedForDeletion ? (
                  <div className="h-72 mb-8 px-2 flex-col gap-2 rounded-md bg-gray-200 flex items-center justify-center">
                    <p className="text-red-500 flex  gap-2">
                      <AiOutlineWarning className="h-5 w-5" />
                      {t("Only_Paid_Member_Can_Send_Message")}
                    </p>
                    <Link
                      to="/payment"
                      className=" text-green-600 text-sm font-medium"
                    >
                      {t("Upgrade_Your_Subscription")}
                    </Link>
                  </div>
                ) : (
                  <div className="h-72 mb-8 px-2 flex-col gap-2 rounded-md bg-gray-200 flex items-center justify-center">
                    <p className="text-red-500 flex  gap-2">
                      <AiOutlineWarning className="h-5 w-5" />
                      {t("Profile_Must_Be_Active_To_Send_Messages")}
                    </p>
                  </div>
                )}

                <div className="absolute bottom-40 right-2 w-full">
                  {ispaid &&
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion && (
                      <div className=" flex items-center w-full justify-between pl-4">
                        <p className="text-green-500 text-xs font-medium">
                          {body.length} /4000
                        </p>
                        <button
                          type="submit"
                          className="bg-pr hover:bg-sr px-2 py-2 text-white rounded-sm"
                        >
                          {t("Send")}
                        </button>
                      </div>
                    )}
                </div>
              </form>
            </div>
          </div>
        ) : (
          <div>
            {data.isinboxarchived
              ? history("/chat/Archived/" + data.inboxid)
              : history("/chat/Active/" + data.inboxid)}
          </div>
        ))}
      {grid ? (
        <div
          className="shadow-md rounded-md overflow-hidden relative bg-white flex flex-column"
          // style={{ height: "450px" }}
          style={{ minHeight: "5rem" }}
        >
          <ToastContainer />
          {data.online ? (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderOnlineTooltip}
            >
              <div
                className={
                  "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                  (language === "ar" ? "left-0" : "right-0")
                }
              ></div>
            </OverlayTrigger>
          ) : (
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderOfflineTooltip}
            >
              <div
                className={
                  "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                  (language === "ar" ? "left-0" : "right-0")
                }
              ></div>
            </OverlayTrigger>
          )}

          {data.isnew ? (
            <div className=" bg-green-500 text-white rounded p-1 absolute z-20 top-0  text-xs ">
              {t("New")}
            </div>
          ) : (
            ""
          )}

          <div
            style={{
              //  height: "250px",
              overflow: "hidden",
              position: "relative",
              zIndex: "10",
            }}
          >
            <Link
              to={"/profile/" + data.profileid}
              // style={{
              //   heigth: "230px",
              //   overflow: "hidden",
              //   position: "relative",
              //   zIndex: "10",
              // }}
            >
              <div class="flex justify-center items-center relative">
                {data.url !== "" ? (
                  <img
                    className={`h-auto ${
                      data.url.includes("avatar") ? "w-full" : "w-full"
                    }`}
                    src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                    alt=""
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                ) : (
                  <img
                    className="h-auto w-auto  "
                    src={imageSrc}
                    alt=""
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
                <div
                  className="absolute bottom-1 right-1  overflow-hidden z-20000 rounded-md "
                  style={{
                    zIndex: "7000",
                  }}
                >
                  <Flag
                    code={data?.countryoforigincode}
                    className="h-6"
                    title={data?.countryoforigin}
                  />
                </div>
              </div>
            </Link>
            {data.ispicturesprivate === true &&
              data.hascurrentuseraccesstoviewcardimages === false &&
              data?.totalimages > 0 && (
                <div
                  onClick={
                    succeed
                      ? null
                      : isCurrentProfileApproved &&
                        isCurrentProfileAvailable &&
                        !isMarkedForDeletion
                      ? handleButtonClick
                      : profileNotActiveOrNotApprovedHandler
                  }
                  // style={{ zIndex: "6000", left: "15%", right: "15%" }}
                  style={{
                    zIndex: "6000",
                    // transform: "translateY(-50%)",
                    // backgroundColor: "lightblue",
                    textAlign: "center",
                  }}
                  className={
                    succeed
                      ? " px-2 py-2 absolute bottom-0 start-0 bg-gray-500 text-white text-sm w-100 "
                      : "px-2 py-2 absolute bottom-0 start-0 hover:bg-sr cursor-pointer  text-white text-sm w-100 bg-pr"
                  }
                >
                  {succeed
                    ? t("Request_Already_Sent")
                    : t("Ask_About_My_Photo")}
                </div>
              )}
          </div>

          <Card.Body className="p-3 flex flex-column ">
            <div className=" overflow-hidden" style={{ minHeight: "6rem" }}>
              <h2
                className={
                  "text-lg text-left font-bold text-gray-800  mb-1 " +
                  (language === "ar" ? "text-right" : "text-left")
                }
                style={{ direction: "ltr" }}
              >
                <Link to={"/profile/" + data.profileid}>
                  {data.displayusername}
                </Link>
              </h2>
              <p className=" text-gray-800 text-sm mb-1 ">
                <span className="font-medium text-sr">{t("Age")}: </span>{" "}
                {data.age}
              </p>
              <p
                className=" text-gray-800  text-sm mb-1 "
                title={data?.cityname ? data?.cityname : ""}
              >
                <span className="font-medium  text-gray-800">
                  {t("Location")}:{" "}
                </span>
                {data?.cityname && data?.cityname.trim() !== "" ? (
                  data?.cityname.length > 15 ? (
                    data?.cityname.substring(0, 15) + " ..."
                  ) : (
                    data?.cityname
                  )
                ) : (
                  <span className="text-pr">{t("No_Data")}</span>
                )}
              </p>

              <div className="border-top border-bottom py-1 my-1">
                <p
                  className=" text-gray-800 text-sm"
                  title={data?.country ? data?.country : ""}
                >
                  <span
                    className="font-medium text-gray-800"
                    title={t("CountryOfResidence")}
                  >
                    {t("CountryOfResidenceCard")}:{" "}
                  </span>
                  {data?.country && data?.country.trim() !== "" ? (
                    data?.country.length > 15 ? (
                      data?.country.substring(0, 15) + " ..."
                    ) : (
                      data?.country
                    )
                  ) : (
                    <span className="text-pr">{t("No_Data")}</span>
                  )}
                </p>
              </div>

              <p className=" text-gray-800  text-sm mb-1">
                <span className="font-medium text-gray-800">
                  {t("Seeking")} :
                </span>{" "}
                {data.seekinggender ? t("Male") : t("Female")}{" "}
                {data.seekingminage}-{data.seekingmaxage}
              </p>
            </div>

            {/* <div className="flex items-center justify-evenly text-xl"> */}
            <div className="flex items-center justify-end gap-3 text-pr text-xl mt-auto">
              <div className="mr-3 md:mr-2 lg:mr-0">
                {like ? (
                  <BsHeartFill
                    className=" text-pink-600"
                    title={t("Already_Expressed_Interest")}
                  />
                ) : (
                  <BsHeart
                    title={t("Express_Interest")}
                    //onClick={likeProfile}
                    onClick={
                      isCurrentProfileApproved &&
                      isCurrentProfileAvailable &&
                      !isMarkedForDeletion
                        ? confirmShowInterestHandler //() => setLikeOpen(true)
                        : profileNotActiveOrNotApprovedHandler
                    }
                    className="cursor-pointer  transition duration-200 hover:scale-110 transform  text-pink-500 hover:text-sr  "
                  />
                )}
              </div>
              <div className="mr-3 md:mr-2 lg:mr-0">
                {" "}
                <RiMessage2Fill
                  title={t("Write_A_Message_By_Clicking_Here")}
                  onClick={
                    isCurrentProfileApproved &&
                    isCurrentProfileAvailable &&
                    !isMarkedForDeletion
                      ? () => setSendmessage(true)
                      : profileNotActiveOrNotApprovedHandler
                  }
                  className="cursor-pointer text-purple-500  hover:text-purple-700  transition duration-200 hover:scale-110 transform  "
                />
              </div>
              <div className="flex items-center gap-1 text-green-700 text-sm">
                {" "}
                <AiFillCamera
                  className=" text-green-700 text-xl"
                  title={t("Number_Of_Images_Attached")}
                />
                {data.totalimages}
              </div>
            </div>
          </Card.Body>
        </div>
      ) : (
        <div className="card mb-3 shadow-md rounded-md ">
          <div className="row g-0 ">
            <div className="col-md-5 relative">
              <Link to={"/profile/" + data.profileid} className="block h-100">
                {data.url !== "" ? (
                  <img
                    // style={{
                    //   objectFit: "cover",
                    //   //height: "300px",
                    // }}
                    className=" w-100 border-dotted border-r border-gray-300"
                    src={`${process.env.REACT_APP_IMAGE}${data.url}`}
                    onContextMenu={(e) => e.preventDefault()}
                    alt="profile img "
                    onError={imageOnError}
                  />
                ) : (
                  <img
                    src={imageSrc}
                    // style={{
                    //   objectFit: "cover",
                    // }}
                    className="h-100 w-100"
                    alt="profile img"
                    onError={imageOnError}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                )}
                <div
                  className="absolute bottom-1 right-1  overflow-hidden z-20 rounded-md"
                  style={{
                    zIndex: "7000",
                  }}
                >
                  <Flag
                    code={data?.countryoforigincode}
                    //height="16"
                    className="h-6"
                    title={data?.countryoforigin}
                    //fallback={<span>?</span>}
                  />
                </div>
              </Link>
              {data.online ? (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderOnlineTooltip}
                >
                  <div
                    className={
                      "w-5 h-5 bg-green-500 rounded-full absolute z-20 top-0 " +
                      (language === "ar" ? "left-0" : "right-0")
                    }
                  ></div>
                </OverlayTrigger>
              ) : (
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={renderOfflineTooltip}
                >
                  <div
                    className={
                      "w-5 h-5 bg-gray-500 rounded-full absolute z-20 top-0 " +
                      (language === "ar" ? "left-0" : "right-0")
                    }
                  ></div>
                </OverlayTrigger>
              )}

              {data.isnew ? (
                <div className=" bg-green-500 text-white rounded p-1 absolute z-20 top-0  text-xs ">
                  {t("New")}
                </div>
              ) : (
                ""
              )}
              {data.ispicturesprivate === true &&
                data.hascurrentuseraccesstoviewcardimages === false &&
                data?.totalimages > 0 && (
                  <div
                    onClick={succeed ? null : handleButtonClick}
                    // style={{ zIndex: "6000" }}
                    className={
                      succeed
                        ? "px-2 py-2 absolute bottom-0 text-center start-0 bg-gray-500  text-white text-sm w-100 " +
                          (language === "ar" ? "right-0" : "left-14")
                        : "px-2 py-2  absolute bottom-0 text-center start-0 hover:bg-sr cursor-pointer  text-white text-sm w-100 bg-pr " +
                          (language === "ar" ? "right-0" : "left-14")
                    }
                  >
                    {succeed
                      ? t("Request_Already_Sent")
                      : t("Ask_About_My_Photo")}
                  </div>
                )}
            </div>
            <div className="col-md-7 p-4">
              <div className="">
                <h2
                  className={
                    " text-gray-800 text-lg font-bold" +
                    (language === "ar" ? " text-right " : " text-left ")
                  }
                  style={{ direction: "ltr" }}
                >
                  <Link to={"/profile/" + data.profileid}>
                    {data.displayusername}
                  </Link>
                </h2>
                <div className="card-header bg-transparent mb-2 px-0 h-20">
                  <p className="text-sm text-muted">
                    {data?.title !== "" ? (
                      data.title.length > 100 ? (
                        `${data.title.slice(0, 100)}...`
                      ) : (
                        data.title
                      )
                    ) : (
                      <span className="text-pr">{t("No_Data")}</span>
                    )}
                  </p>
                </div>

                <div className=" justify-between mb-1">
                  <p className="text-gray-800 ">
                    <span className="font-medium text-sr">{t("Age")}:</span>{" "}
                    {data.age}
                  </p>

                  {/* {data.cityname !== "" && data?.cityname.trim() !== "" && (
                    <p
                      className="text-gray-800 font-sm mb-1"
                      title={data?.cityname ? data?.cityname : ""}
                    >
                      <span className="font-medium text-sr">
                        {t("Location")}:{" "}
                      </span>{" "}
                      {data?.cityname.length > 15
                        ? data?.cityname.substring(0, 15) + " ..."
                        : data?.cityname}
                    </p>
                  )} */}
                  <p
                    className=" text-gray-800  text-sm mb-1 "
                    title={data?.cityname ? data?.cityname : ""}
                  >
                    <span className="font-medium  text-gray-800">
                      {t("Location")}:{" "}
                    </span>
                    {data?.cityname && data?.cityname.trim() !== "" ? (
                      data?.cityname.length > 15 ? (
                        data?.cityname.substring(0, 15) + " ..."
                      ) : (
                        data?.cityname
                      )
                    ) : (
                      <span className="text-pr">{t("No_Data")}</span>
                    )}
                  </p>
                </div>

                {data.country !== null && (
                  <p
                    className="text-gray-800 font-sm mb-1"
                    title={data?.country ? data?.country : ""}
                  >
                    {" "}
                    <span
                      className="font-medium text-sr"
                      title={t("CountryOfResidence")}
                    >
                      {t("CountryOfResidenceCard")}:{" "}
                    </span>{" "}
                    {data?.country && data?.country.trim() !== "" ? (
                      data?.country.length > 15 ? (
                        data?.country.substring(0, 15) + " ..."
                      ) : (
                        data?.country
                      )
                    ) : (
                      <span className="text-pr">{t("No_Data")}</span>
                    )}
                  </p>
                )}

                <p className="text-gray-800 text-sm mb-1">
                  {" "}
                  <span className="font-medium text-sr">
                    {t("Last_Active")}:{" "}
                  </span>{" "}
                  {data.lastactivitydatetime !== null ? (
                    data.lastactivitydatetime.slice(0, 10)
                  ) : (
                    <span className="text-pr">{t("No_Data")}</span>
                  )}
                </p>
                {/* <p className="text-gray-800 font-sm mb-1">
                  {" "}
                  <span className="font-medium text-sr">
                    {t("Subscription_Type")}:{" "}
                  </span>{" "}
                  {data.subscriptiontype !== null ? (
                    data.subscriptiontype.slice(0, 10)
                  ) : (
                    <span className="text-pr">{t("No_Data")}</span>
                  )}
                </p>

                <p className="text-gray-800 font-sm mb-1">
                  {" "}
                  <span className="font-medium text-sr">
                    {t("Account_Type")}:{" "}
                  </span>{" "}
                  {data.isnew ? (
                    `${t("New_Account")}`
                  ) : (
                    <span className="text-sr">{t("Old_Account")}</span>
                  )}
                </p> */}
                <div className="flex justify-between">
                  <p className="text-gray-800  font-sm">
                    <span className="font-medium text-sr">{t("Seeking")}:</span>{" "}
                    {data.seekinggender ? t("Male") : t("Female")}{" "}
                    {data.seekingminage}-{data.seekingmaxage}
                  </p>
                  {/* <p
                    className={
                      "text-sm font-bold " +
                      (data.online ? " text-green-600 " : "text-gray-500 ")
                    }
                  >
                    <span className="text-sr ">{t("Active")} :</span>{" "}
                    {data.online ? t("Online") : t("Offline")}
                  </p> */}
                </div>
                <div className="flex items-center justify-end gap-3 text-pr text-xl mt-4">
                  <div>
                    {like ? (
                      <BsHeartFill
                        className=" text-pink-600"
                        title={t("Already_Expressed_Interest")}
                      />
                    ) : (
                      <BsHeart
                        title={t("Express_Interest")}
                        //onClick={likeProfile}
                        //onClick={() => setLikeOpen(true)}
                        onClick={confirmShowInterestHandler}
                        className="cursor-pointer  transition duration-200 hover:scale-110 transform  text-pink-500 hover:text-sr  "
                      />
                    )}
                  </div>
                  <div>
                    {" "}
                    <RiMessage2Fill
                      title={t("Write_A_Message_By_Clicking_Here")}
                      onClick={() => setSendmessage(true)}
                      className="cursor-pointer text-purple-500  hover:text-purple-700  transition duration-200 hover:scale-110 transform  "
                    />
                  </div>
                  <div className="flex items-center gap-1 text-green-700 text-sm">
                    {" "}
                    <AiFillCamera
                      title={t("Number_Of_Images_Attached")}
                      className=" text-green-700 text-xl"
                    />
                    {data.totalimages}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Cart;
